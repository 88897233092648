import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { AuthService } from './auth.service';
import { NavComponent } from './nav/nav.component';
import { NamePipe } from './name.pipe';

@NgModule({
	imports: [
	CommonModule,
		MatIconModule,
		MatListModule,
		MatToolbarModule,
		RouterModule,
		MatButtonModule,
		MatSnackBarModule,
		MatSidenavModule
	],
	providers: [AuthService],

	exports: [ NavComponent, NamePipe ],
	declarations: [ NavComponent, NamePipe ]
})
export class CoreModule {}
