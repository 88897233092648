import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Present } from '../../lists/lists.model';
import { mergeMap, map, tap, filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Profile } from '../profile.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import {
	Overlay,
	OverlayRef,
	FlexibleConnectedPositionStrategy,
	GlobalPositionStrategy,
	NoopScrollStrategy
} from '@angular/cdk/overlay';
import { ComponentPortal, CdkPortal, Portal } from '@angular/cdk/portal';
import * as moment from 'moment';
import { TimerObservable } from 'rxjs/observable/TimerObservable';

@Component({
	selector: 'app-view-profile',
	templateUrl: './view-profile.component.html',
	styleUrls: [ './view-profile.component.scss' ]
})
export class ViewProfileComponent implements OnInit, OnDestroy {
	uid: string;
	name: string;

	timeTilChristmas = new Observable<string>();

	@ViewChild('name') nameElement: ElementRef;
	@ViewChild(CdkPortal) newPresentForm: ElementRef;

	presentCollection: AngularFirestoreCollection;

	overlayRef: OverlayRef;

	urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
	addPresentForm = new FormGroup({
		name: new FormControl('', [ Validators.required ]),
		url: new FormControl('', [ Validators.pattern(this.urlRegex) ])
	});

	private destroy$ = new Subject<void>();

	constructor(private afs: AngularFirestore, private afauth: AngularFireAuth, private overlay: Overlay) {
		const timer = TimerObservable.create(0, 1000);
		const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
		d.setUTCSeconds(1545695999);
		this.timeTilChristmas = timer.pipe(takeUntil(this.destroy$), map(() => {
			const momentvalue = moment.duration(moment('12252019', 'MMDD').unix() -  moment().unix(), 'seconds');
			return `There are
							${momentvalue.months().toString()} months,
							${momentvalue.days().toString()} days,
							${momentvalue.hours().toString()} hours,
							${momentvalue.minutes().toString()} minutes,
							${momentvalue.seconds().toString()} seconds,
							 until Christmas!`;
		}));
	}

	ngOnInit() {
		const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
		this.overlayRef = this.overlay.create({
			hasBackdrop: true,
			positionStrategy: positionStrategy,
			width: '500px',
			scrollStrategy: new NoopScrollStrategy()
		});
		this.overlayRef.backdropClick().pipe(takeUntil(this.destroy$)).subscribe(() => this.close());
		this.afauth.user.subscribe((user) => {
			this.afs
				.doc<Profile>(`users/${user.uid}`)
				.snapshotChanges()
				.pipe(
					takeUntil(this.destroy$),
					filter((r) => {
						if (r.payload.exists) {
							return true;
						} else {
							this.create();
							return false;
						}
					}),
					map((r) => {
						return <Profile>{
							uid: r.payload.id,
							name: r.payload.data().name
						};
					})
				)
				.subscribe((r) => {
					this.uid = r.uid;
					this.name = r.name;
					this.presentCollection = this.afs.doc(`users/${r.uid}`).collection('presents');
				});
		});
	}

	create() {
		this.afauth.user.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			this.afs
				.collection('users')
				.doc(user.uid)
				.set({
					name: user.displayName,
					profileImage: user.photoURL,
				})
				.then(() => {
					this.uid = user.uid;
					this.name = user.displayName;
				});
		});
	}

	save(event: Event) {
		if (event) {
			event.preventDefault();
		}
		const present = this.addPresentForm.value as Present;
		this.presentCollection.add(present).then(() => {
			this.addPresentForm.reset();
			this.close();
		});
	}

	close(): void {
		this.overlayRef.detach();
	}

	addPresent() {
		this.overlayRef.attach(this.newPresentForm);
		this.nameElement.nativeElement.focus();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}
}
