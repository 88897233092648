import {
	Component,
	OnInit,
	Input,
	ElementRef,
	ViewChildren,
	ViewChild,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import { Present } from '../lists.model';
import { mergeMap, map, filter, merge, mergeAll, concatMap, concat, takeUntil } from 'rxjs/operators';
import {
	AngularFirestore,
	AngularFirestoreCollection,
	DocumentReference,
	DocumentSnapshot
} from '@angular/fire/firestore';
import { Observable, from, Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Profile } from '../../profile/profile.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Directionality } from '@angular/cdk/bidi';
import { CdkPortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay, NoopScrollStrategy } from '@angular/cdk/overlay';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
	selector: 'app-view-list',
	templateUrl: './view-list.component.html',
	styleUrls: [ './view-list.component.scss' ]
})
export class ViewListComponent implements OnInit, OnDestroy {
	@Input() uid;
	@Input() doesOwn = false;

	@ViewChild(CdkPortal) newPresentForm: ElementRef;

	toDelete: Present = null;

	hasLoaded = false;
	userName = '';
	userProfileImage = '';

	isEmpty = false;
	presentCollection: AngularFirestoreCollection;
	overlayRef: OverlayRef;
	mobileQuery: MediaQueryList;

	private destroy$ = new Subject<void>();
	private _mobileQueryListener: () => void;

	displayedColumns: string[] = [ 'isTaken', 'name', 'url' ];

	presents: Present[];

	constructor(
		private afs: AngularFirestore,
		private route: ActivatedRoute,
		private router: Router,
		private afauth: AngularFireAuth,
		private snackBar: MatSnackBar,
		private overlay: Overlay,
		private media: MediaMatcher,
		private cd: ChangeDetectorRef
	) {
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => cd.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	ngOnInit() {
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.hasLoaded = false;
			this.userName = '';
			this.userProfileImage = '';

			this.isEmpty = false;
			this.presents = [];
		});
		const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
		this.overlayRef = this.overlay.create({
			hasBackdrop: true,
			positionStrategy: positionStrategy,
			width: '500px',
			scrollStrategy: new NoopScrollStrategy()
		});

		if (!this.uid) {
			this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
				if (params['uid']) {
					this.uid = params['uid'];
					this.doesOwn = this.uid === this.afauth.auth.currentUser.uid;
				}
				this.loadPresents();
			});
		} else {
			this.loadPresents();
		}
	}

	loadPresents() {
		if (this.doesOwn) {
			this.displayedColumns.shift();
			this.displayedColumns.push('delete-button');
		}
		this.presentCollection = this.afs.doc(`users/${this.uid}`).collection('presents');

		const subscriptions = this.afs.collection('users').doc<Profile>(this.uid).get().pipe(
			takeUntil(this.destroy$),
			concat(
				this.presentCollection.snapshotChanges().pipe(
					takeUntil(this.destroy$),
					map((value) => {
						return value.map((presentSS) => {
							const present = presentSS.payload.doc.data();
							return <Present>{
								id: presentSS.payload.doc.id,
								isTaken: !!present.isTaken,
								name: present.name,
								url: present.url,
								takenByCurrentUser:
									!!present.isTaken &&
									(<DocumentReference>present.isTaken).id === this.afauth.auth.currentUser.uid
							};
						});
					})
				)
			)
		);
		subscriptions
		.pipe(takeUntil(this.destroy$))
		.subscribe((presents) => {
			this.hasLoaded = true;
			if ((<DocumentSnapshot<Profile>>presents).exists) {
				const data = (presents as DocumentSnapshot<Profile>).data();
				this.userName = data.name;
				this.userProfileImage = data.profileImage;
			} else {
				this.presents = <Present[]>presents;
				if (this.presents.length === 0) {
					this.isEmpty = true;
				}
			}
		});
	}

	dibs(present: Present): void {
		this.presentCollection.doc(present.id).set(
			{
				isTaken: this.afs.doc(`users/${this.afauth.auth.currentUser.uid}`).ref
			},
			{ merge: true }
		);
	}

	removeDibs(present: Present): void {
		this.presentCollection.doc(present.id).set(
			{
				isTaken: null
			},
			{ merge: true }
		);
	}

	delete(present: Present) {
		this.toDelete = present;
		this.overlayRef.attach(this.newPresentForm);
	}

	confirmedDelete() {
		this.presentCollection.doc(this.toDelete.id).delete().then(() => {
			this.closeDelete();
		});
	}

	closeDelete(): void {
		this.toDelete = null;
		this.overlayRef.detach();
	}
	changeName(present: Present, event: Event) {
		const name = event.srcElement.textContent.trim();
		if (present.name.trim() !== name) {
			this.presentCollection
				.doc<Partial<Present>>(present.id)
				.set(
					{
						name
					},
					{ merge: true }
				)
				.then(() => {
					this.snackBar.open('Successfully updated present name', null, {
						duration: 1000
					});
				})
				.catch(() => {
					this.snackBar.open('Failed to update present name', null, {
						duration: 1000
					});
				});
		}
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	changeURL(present: Present, event: Event) {
		const url = event.srcElement.textContent.trim();
		if (present.url.trim() !== url) {
			this.presentCollection
				.doc<Partial<Present>>(present.id)
				.set(
					{
						url
					},
					{ merge: true }
				)
				.then(() => {
					this.snackBar.open('Successfully updated present URL', null, {
						duration: 1000
					});
				})
				.catch(() => {
					this.snackBar.open('Failed to update present URL', null, {
						duration: 1000
					});
				});
		}
	}
}
