import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'firebase/app';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class AuthService implements OnDestroy {
	redirectUrl: string;
	isLoggedIn = new BehaviorSubject(false);

	private destroy$ = new Subject<void>();

	constructor(private af: AngularFireAuth, private router: Router) {
		this.af.authState.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			if (user) {
				this.isLoggedIn.next(true);
				if (this.redirectUrl) {
					this.router.navigate([ this.redirectUrl ]);
				}
			} else {
				this.isLoggedIn.next(false);
			}
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

}
