import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewProfileComponent } from './view-profile/view-profile.component';
import { ListsModule } from '../lists/lists.module';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { PortalModule } from '@angular/cdk/portal';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from '../core/core.module';

@NgModule({
	imports: [
		CommonModule,
		ListsModule,
		MatCardModule,
		RouterModule,
		MatInputModule,
		ReactiveFormsModule,
		FormsModule,
		MatFormFieldModule,
		MatButtonModule,
		PortalModule,
		MatButtonModule,
		MatIconModule,
		CoreModule,
	],
	declarations: [ ViewProfileComponent ]
})
export class ProfileModule {}
