import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment.prod';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { ListsModule } from './lists/lists.module';
import { ProfileModule } from './profile/profile.module';
import { CoreModule } from './core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AuthGuardService } from './app-auth-guard.service';
import { WelcomeComponent } from './welcome/welcome.component';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
	declarations: [ AppComponent, WelcomeComponent ],
	imports: [
BrowserModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireAuthModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ListsModule,
		ProfileModule,
		CoreModule,
		PortalModule,
		MatCardModule,
		MatInputModule,
		PortalModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
	],
	providers: [ AuthGuardService ],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
