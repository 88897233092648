import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { filter, takeUntil } from 'rxjs/operators';
import { Present } from '../lists.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { Profile } from 'src/app/profile/profile.model';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-selected-presents',
	templateUrl: './selected-presents.component.html',
	styleUrls: [ './selected-presents.component.css' ]
})
export class SelectedPresentsComponent implements OnInit, OnDestroy {
	presents = new Array<PresentWithOwner>();
	displayedColumns: string[] = [ 'profilePhoto', 'ownerName', 'presents', 'openIcon' ];
	isLoaded = false;
	private destroy$ = new Subject<void>();

	constructor(private afs: AngularFirestore, private afauth: AngularFireAuth, private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.afs.collection<Profile>('users').get().pipe(takeUntil(this.destroy$)).subscribe((users) => {
			users.docs.filter((user) => user.id !== this.afauth.auth.currentUser.uid).forEach((user) => {
				user.ref.collection('presents').get().then((ssPresents) => {
					this.presents = [
						...this.presents,
						{
							ownerName: user.data()['name'],
							ownerPhoto: user.data()['profileImage'],
							ownerId: user.id.toString(),
							presents: ssPresents.docs
								.map((present) => {
									return present.data();
								})
								.filter((present) => {
									return (
										present['isTaken'] &&
										(present['isTaken'] as DocumentReference).id ===
											this.afauth.auth.currentUser.uid
									);
								})
								.map((document) => {
									return new Present(document);
								})
						}
					];
				}).then(() => {
					this.isLoaded = true;
				});
			});
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}
}

class PresentWithOwner {
	ownerName: string;
	ownerId: string;
	ownerPhoto: string;
	presents?: Array<Present> = [];
}
