import { Component, OnInit, OnDestroy } from '@angular/core';
import { Profile } from 'src/app/profile/profile.model';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { map, takeUntil } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-family-list',
	templateUrl: './family-list.component.html',
	styleUrls: [ './family-list.component.css' ]
})
export class FamilyListComponent implements OnInit, OnDestroy {
	familyCollection: AngularFirestoreCollection;
	profiles: Array<Profile>;
	displayedColumns: string[] = [ 'name', 'viewListLink' ];
	hasLoaded = false;

	private destroy$ = new Subject<void>();

	constructor(private afs: AngularFirestore, private afauth: AngularFireAuth) {}

	ngOnInit() {
		this.familyCollection = this.afs.collection(`users`);

		this.familyCollection
			.snapshotChanges()
			.pipe(
				takeUntil(this.destroy$),
				map((value) => {
					return value
						.filter((profile) => {
							return profile.payload.doc.id !== this.afauth.auth.currentUser.uid;
						})
						.map((profile) => {
							const v = profile.payload.doc.data();
							return {
								name: v.name,
								uid: profile.payload.doc.id
							} as Profile;
						});
				})
			)
			.subscribe((p) => {
				this.hasLoaded = true;
				this.profiles = p;
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}
}
