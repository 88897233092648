import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './app-auth-guard.service';
import { ViewListComponent } from './lists/view-list/view-list.component';
import { ViewProfileComponent } from './profile/view-profile/view-profile.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FamilyListComponent } from './lists/family-list/family-list.component';


const routes: Routes = [
	{
		path: 'list/:uid',
		component: ViewListComponent,
		canActivate: [ AuthGuardService ]
	},
	{
		path: '',
		component: WelcomeComponent,
	},
	{
		path: 'me',
		component: ViewProfileComponent,
		canActivate: [ AuthGuardService ]
	},
	{
		path: 'family',
		component: FamilyListComponent,
		canActivate: [ AuthGuardService ]
	},
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
exports: [ RouterModule ]
})
export class AppRoutingModule {}
