import { Injectable, OnDestroy } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject } from 'rxjs';
import { mergeMap, map, takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

@Injectable()
export class AuthGuardService implements CanActivate, OnDestroy {
	private destroy$ = new Subject<void>();

	constructor(public auth: AngularFireAuth, public router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		return this.auth.authState.pipe(
			takeUntil(this.destroy$),
			map((auth) => {
				if (isNullOrUndefined(auth)) {
					this.router.navigate([ '/' ]);
					return false;
				} else {
					return true;
				}
			})
		);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}
}
