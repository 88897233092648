import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User, auth } from 'firebase/app';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { isNullOrUndefined } from 'util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: [ './nav.component.scss' ]
})
export class NavComponent implements OnInit, OnDestroy {
	user: User;

	private destroy$ = new Subject<void>();

	constructor(private afAuth: AngularFireAuth, private router: Router, private snackbar: MatSnackBar) {}

	ngOnInit() {
		this.afAuth.user.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			this.user = user;
			if (!isNullOrUndefined(this.user)) {
				this.router.navigate(['me']);
			}
		});
	}

	logout() {
		this.afAuth.auth.signOut().then(() => {
			this.router.navigate([ '/' ]);
		});
	}

	login() {
		this.afAuth.auth
			.signInWithPopup(new auth.GoogleAuthProvider())
			.then(() => {
				this.router.navigate([ '/', 'me' ]);
			})
			.catch((err) => {
				this.snackbar.open(err, null, {
					duration: 3000
				});
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}
}
