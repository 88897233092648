import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'name'
})
export class NamePipe implements PipeTransform {
	transform(value: string, args?: any): any {
		if (value) {
			return value.split(' ')[0];
		}
		return '';
	}
}
