export const environment = {
	production: true,
	firebase: {
		apiKey: 'AIzaSyBogMbj-DpbHRBrsCnlkIuzLcssf5fAzZ0',
		authDomain: 'christmas-presents.firebaseapp.com',
		databaseURL: 'https://christmas-presents.firebaseio.com',
		projectId: 'christmas-presents',
		storageBucket: 'christmas-presents.appspot.com',
		messagingSenderId: '502114546267'
	}
};
