import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewListComponent } from './view-list/view-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FamilyListComponent } from './family-list/family-list.component';
import { RouterModule } from '@angular/router';
import { SelectedPresentsComponent } from './selected-presents/selected-presents.component';
import { PortalModule } from '@angular/cdk/portal';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
	imports: [
		CommonModule,
		MatTableModule,
		MatIconModule,
		MatButtonModule,
		MatCardModule,
		RouterModule,
		MatProgressSpinnerModule,
		PortalModule,
		MatRippleModule
	],
	declarations: [ ViewListComponent, FamilyListComponent, SelectedPresentsComponent ],
	exports: [ ViewListComponent, SelectedPresentsComponent ]
})
export class ListsModule {}
