export class Present {
	id: string;
	name: string;
	extraDetails: string;
	url: string;
	isTaken: boolean;
	takenByCurrentUser: boolean;

	constructor({
		id,
		name,
		extraDetails,
		url,
		isTaken,
		takenByCurrentUser
	}: Partial<Present>) {
		this.id = id;
		this.name = name;
		this.extraDetails = extraDetails;
		this.url = url;
		this.isTaken = isTaken;
		this.takenByCurrentUser = takenByCurrentUser;
	}
}
